import { mapGetters } from 'vuex';
import PoweredBy from '@/shared/powered-by/index.vue';

// @vue/component
export default {
  name: 'AppFooter',

  components: {
    PoweredBy,
  },

  computed: {
    footerStyle() {
      if (!this.footerOffset) return {};

      return {
        'padding-bottom': `${this.footerOffset}px`,
      };
    },

    ...mapGetters(['isUserCoach', 'footerOffset']),
  },
};
