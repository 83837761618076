import header from './header';
import footer from './footer';
import cookies_policy from './cookies';
import home from './home';
import media from './media';
import modal from './modal';
import plan_card from './plan_card';
import plan_billing from './plan_billing';

import pages from './pages';

import sign_up from './auth/sign_up';
import confirmation from './auth/confirmation.js';
import forgot_password from './auth/forgot-password.js';
import reset_password from './auth/reset-password.js';
import sign_in from './auth/sign-in.js';
import auth_success from './auth/success.js';

import feed from './feed';
import profile from './profile';
import membership from './membership';
import leaderboards from './leaderboards/index.js';

import fields from './common/fields';
import errors from './common/errors';
import buttons from './common/buttons';
import shared from './shared';

export default {
  header,
  footer,
  home,
  cookies_policy,
  media,
  modal,
  plan_card,
  plan_billing,
  pages,
  shared,

  auth: {
    sign_in,
    sign_up,
    confirmation,
    forgot_password,
    reset_password,
    success: auth_success,

    dont_have_account: "Don't have an account?",
    sign_up_link: 'Sign up',

    apply_promo_code_at_checkout: 'Apply Promo code at checkout',
  },

  feed,
  profile,
  membership,
  leaderboards,

  update_modal: {
    title: 'New System Update',
    text: "The update requires a site reload to work correctly. Please save anything you're working on before reloading the site. Unsaved changes will be lost.",
    ok: 'Reload now',
    cancel: 'Cancel',
  },

  fields,
  errors,
  buttons,

  gender: {
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
};
